.product-card {
  width: 100%;
  position: relative;
}

.product-card .main-content {
  width: 100%;
  height: 400px;
  position: relative;
}

.product-card .main-content img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.product-card .main-content .size-selector {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* min-height: 90px; */
  max-height: 100%;
  background: linear-gradient(268.56deg, #000000 0%, #373737 100%);
  /* padding-top: 10px; */
  color: white;
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.product-card .main-content:hover .size-selector,
.product-card .main-content .size-selector.active {
  opacity: 1;
}

.product-card .main-content .size-selector p {
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.product-card .main-content .size-selector .size-selector-items {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.product-card .main-content .size-selector .size-selector-items button {
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  background: transparent;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-radius: 50px;
}

.product-card .main-content .size-selector .size-selector-items button:hover {
  border-color: white;
}

.product-card .main-content .size-selector .size-selector-items button.active {
  background-color: white;
  color: #000000;
}

.product-card .product-card-like {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.product-card .color-selector button {
  border: none;
  outline: none;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.product-card .color-selector button.active {
  box-shadow: 0px 0px 0px 1px black inset;
}
