.about-gallery {
  padding: 60px 0;
  background-color: white;
}

.about-gallery .top-images img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.about-gallery .bottom-images img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
