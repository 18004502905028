.triple-image {
  position: relative;
  height: 800px;
  margin: auto;
}

.triple-image .first {
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: cover;
}

.triple-image .second {
  position: absolute;
  left: 200px;
  bottom: 300px;
  object-fit: cover;
}

.triple-image .third {
  position: absolute;
  right: 0;
  bottom: 100px;
  object-fit: cover;
}

@media screen and (max-width: 620px) {
  .triple-image {
    height: 1100px;
    margin-top: 100px;
  }

  .triple-image .first {
    left: auto;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  .triple-image .second {
    left: 0;
    bottom: 410px;
    z-index: 2;
  }

  .triple-image .third {
    right: 0;
    top: 0;
    z-index: 1;
  }
}
