.language-currency {
  width: 250px;
  border-radius: 15px;
  overflow: hidden;
}

.language-currency .language-currency-header p {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(245, 245, 245, 1);
  transition: all 0.3s ease-in-out;
}

.language-currency .language-currency-header p.active {
  background-color: white;
  color: rgba(11, 153, 255, 1);
}

.language-currency .language-currency-content {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
}

.language-currency
  .language-currency-content
  .language-currency-content-language-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.language-currency
  .language-currency-content
  .language-currency-content-language-item
  .symbol {
  color: rgba(189, 189, 189, 1);
}

.language-currency
  .language-currency-content
  .language-currency-content-language-item
  .label {
  transition: all 0.3s ease-in-out;
}

.language-currency
  .language-currency-content
  .language-currency-content-language-item.active
  > .label {
  color: rgba(11, 153, 255, 1);
}

.navbar .menu {
  background: linear-gradient(360deg, #f1f1f1 0%, #ffffff 100%);
  padding: 10px 0;
}

.navbar .menu .logo h1 {
  text-align: center;
}

.navbar .menu .logo p {
  color: rgba(173, 173, 173, 1);
}

.navbar .menu .menu-links .menu-links-item {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.menu-links-item-content {
  padding: 20px;
  overflow: hidden;
  gap: 20px;
  flex-direction: column;
}

.menu-links-item-content a {
  color: black;
  text-transform: uppercase;
}

.menu-links-item-content a:hover {
  color: rgba(11, 153, 255, 1);
}

.navbar .menu .menu-links .menu-links-item:nth-child(2):hover {
  color: rgba(11, 153, 255, 1);
}

.mobile-navbar-menu {
}

.mobile-navbar-menu li a,
.mobile-navbar-menu li p {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  color: white;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.mobile-navbar-menu li a:hover,
.mobile-navbar-menu li p:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
