.category-header {
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.category-header * {
  z-index: 1;
}

.category-header::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.797),
    rgba(0, 0, 0, 0) 30%
  );
}

.category-filter {
  background: linear-gradient(360deg, #f1f1f1 0%, #ffffff 100%);
  padding: 10px 0;
}
