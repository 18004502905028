.product-images-item img {
  object-fit: cover;
}

.product-details {
  padding: 10px 15px;
}

.product-details .color-selector button {
  border: none;
  outline: none;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.product-details .color-selector button.active {
  box-shadow: 0px 0px 0px 1px black inset;
}

.product-details .size-selector-items button {
  outline: none;
  border: none;
  padding: 10px 15px;
  border: 1px solid rgba(233, 233, 233, 1);
  border-radius: 50px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.product-details .size-selector-items button.active {
  background-color: rgba(233, 233, 233, 1);
}

.product-details .paragraph-success {
  color: rgba(40, 205, 65, 1);
}

.product-details .paragraph-error {
  color: rgb(255, 58, 58);
}

.product-images-swiper .product-images-swiper-slide img {
  object-fit: cover;
}

.product-details .product-collapse .ant-collapse-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
