.footer {
  background-color: white;
  padding-top: 30px;
}

.footer .links ol li a {
  display: block;
  color: rgba(111, 111, 111, 1);
  padding: 10px 0;
  font-weight: 400;
}

.footer .links ol li a:hover {
  color: black;
}

.footer .links ol li h3 a {
  color: dodgerblue;
}
