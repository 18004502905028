.carousel-swiper {
  width: 70%;
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  margin: auto;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .carousel-swiper {
    width: 100%;
  }
}

/* .carousel-swiper::after {
  content: '';
  height: 100%;
  width: 200px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 10;
}

.carousel-swiper::before {
  content: '';
  height: 100%;
  width: 200px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  z-index: 10;
} */

.carousel-swiper-item {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
  opacity: 0.3;
  transition: all 0.3s ease-in-out !important;
}

.carousel-swiper-item.swiper-slide-active {
  opacity: 1;
}

.carousel-swiper-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
