@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
}

.no-scroll {
  overflow: hidden;
}

.jetbrains-mono,
p,
span {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  /* line-height: 30px; */
}

.cormorant-sc-light {
  font-family: 'Cormorant SC', serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}

.cormorant-sc-regular {
  font-family: 'Cormorant SC', serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

.cormorant-sc-medium {
  font-family: 'Cormorant SC', serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

.cormorant-sc-semibold {
  font-family: 'Cormorant SC', serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
}

.cormorant-sc-bold {
  font-family: 'Cormorant SC', serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.cormorant-title {
  font-family: 'Cormorant SC', serif;
  font-weight: 500;
  font-style: normal;
  font-size: 50px;
  text-transform: uppercase;
  word-break: break-word;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-input,
.ant-picker-input {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
