.home .categories {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
}

.home .categories .categories-item {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.home .categories .categories-item a .overlay {
  content: '';
  width: 100%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.home .categories .categories-item a img {
  width: 100%;
  height: 500px;
  position: relative;
  object-fit: cover;
}

.home .categories .categories-item a .content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  color: white;
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.home .categories .categories-item:hover > a .overlay {
  opacity: 0;
}

.home .categories .categories-item:hover > a .content {
  opacity: 1;
}

.collection {
  padding: 50px 0;
}

.collection .collection-swiper-wrapper {
  flex-grow: 1;
  max-width: 100%;
}

.collection-swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 15px solid white;
  background-color: white;
}

.collection-swiper-slide img {
  flex-grow: 1;
  object-fit: cover;
  height: calc(100% - 50px);
}
.collection-swiper-slide div {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.collection-swiper-slide div p:nth-child(2) {
  color: rgba(255, 59, 48, 1);
}

.get-the-look {
  padding: 70px 0;
  background-color: white;
}

.get-the-look .get-the-look-swiper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.get-the-look .get-the-look-swiper-item {
  text-align: center;
  position: relative;
}

.get-the-look .get-the-look-swiper-item img {
  object-fit: cover;
  height: 440px;
  width: 100%;
  display: block;
}

.get-the-look .get-the-look-swiper-item .overlay {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 1;
}

.get-the-look .get-the-look-swiper-item::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #00000078, #00000000);
}

.get-the-look .get-the-look-swiper-placeholder {
  margin-top: 10px;
  width: 100%;
  height: 5px;
  background-color: rgba(233, 233, 233, 1);
}

.get-the-look .get-the-look-swiper-placeholder-active {
  height: 5px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}

.videos {
  padding: 70px 0;
  background-color: white;
}

.videos .videos-swiper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.videos .videos-swiper-item {
  text-align: center;
  position: relative;
}

.videos .videos-swiper-item video {
  object-fit: cover;
  height: 440px;
  width: 100%;
  display: block;
}

.videos .videos-swiper-placeholder {
  margin-top: 10px;
  width: 100%;
  height: 5px;
  background-color: rgba(233, 233, 233, 1);
}

.videos .videos-swiper-placeholder-active {
  height: 5px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}
