.about .showroom {
  padding: 60px 0;
  background-color: black;
  color: white;
  height: 700px;
  margin-bottom: 250px;
}

.about .showroom img {
  display: block;
  margin: auto;
  margin-top: 50px;
  max-width: 100%;
  width: 550px;
  height: 650px;
  object-fit: cover;
}
